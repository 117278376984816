import React, { useState } from "react";
import "styles/global.css";
//import iconShoppingCart from "../../../static/images/ico-shoppingcart.png"
import { Trans } from "@lingui/macro";
import "./ProductDetail.css"
import {MDXRenderer} from "gatsby-plugin-mdx";
import {ShoppingCart} from "../../utils/shop"

const ProductDetail = ({locale, item}) => {
    const [priceVat, setPriceVat] = useState(item.frontmatter.priceVat[0]);
    const [priceNoVat, setPriceNoVat] = useState(item.frontmatter.priceNoVat[0]);
    const [title, setTitle] = useState(item.frontmatter.option[0]);
    const [product, setProduct] = useState(item.frontmatter.id[0]);
    const [disclaimer, setDisclaimer] = useState(item.frontmatter.disclaimer[0]);
    const [image, setImage] = useState(item.frontmatter.gallery[0]);


    function onSubmit(e) {
        e.preventDefault();
        let shoppingCart = new ShoppingCart();

        shoppingCart.add(
            product,
            item.frontmatter.title + " " + title,
            priceVat,
            priceNoVat,
            item.frontmatter.vat,
            item.frontmatter.unit,
            document.getElementById('amount').value,

        );
        window.location = '/'+locale+'/kosik/';
        return false;
    }

    function variantChange(e, option) {
        setPriceVat(item.frontmatter.priceVat[e]);
        setPriceNoVat(item.frontmatter.priceNoVat[e]);
        setTitle(item.frontmatter.option[e]);
        setProduct(item.frontmatter.id[e]);
        setDisclaimer(item.frontmatter.disclaimer[e]);
        setImage(item.frontmatter.gallery[e]);

    }

  return (
      <div className="ProductDetail">
          <div className="c20p">
              <div className="img"><img src={image.image.small.fluid.src} alt={item.frontmatter.title}/></div>
          </div>
          <div className="c80p">
              <div className="c70p">
                  <div className="description">
                      <h2>{item.frontmatter.title + " " + title}</h2>
                      <MDXRenderer>{item.body}</MDXRenderer>
                  </div>
                  <div className="description">
<p>      <strong><Trans>Trvanlivosť:</Trans></strong>  {item.frontmatter.longevity} <Trans>dní</Trans></p><br/>
{ disclaimer === 1 ? <p>      <strong><Trans>UPOZORNENIE:</Trans></strong> <Trans>Tovar s orientačnou váhou</Trans></p> : ""}

                  </div>
              </div>
              <div className="c30p">
              
                  <h2><Trans>Cena</Trans></h2>
                  <p className="price">
                      <span className="priceVat">{priceVat}&euro; <Trans>s DPH</Trans> / {item.frontmatter.unit}</span>
                      <span className="priceNoVat">{priceNoVat}&euro; <Trans>bez DPH</Trans> / {item.frontmatter.unit}</span>
                  </p>

                  <form onSubmitCapture={onSubmit}>
                  <div className="form-row">
                  <label><Trans>Varianta</Trans></label>
                  <select onChange={(e)=>{variantChange(e.target.value)}}>
      {item.frontmatter.option.map((option, id) => (
        <option
          key={option}
          value={id}
          defaultValue={0}
        >
          {option}
        </option>
      ))}
    </select>
                  </div>
                      <input type="hidden" id="id" value={item.frontmatter.id}/>
                      <div className="form-row">
                          <label><Trans>Množstvo</Trans> ({item.frontmatter.unit})</label>
                          <input type="number" id="amount" defaultValue="1"  min="1" max="100" required />
                      </div>
                      <div className="form-row">
                          <button type="submit"><Trans>Pridať do košíka</Trans></button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  );
};

export default ProductDetail;
