import React from "react";
import Layout from "components/layout/layout";
import SEO from "components/seo";
import ProductDetail from "../components/ProductDetail/ProductDetail";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";

const ProductPage = ({ pageContext, location }) => {
    return (
        <Layout locale={pageContext.item.frontmatter.language} location={location}>
            <Header locale={pageContext.item.frontmatter.language} />
            <div className="MainContent">
                <section>
                    <div className="container">
                        <ProductDetail item={pageContext.item} locale={pageContext.item.frontmatter.language}/>
                    </div>
                </section>
            </div>
            <Footer locale={pageContext.item.frontmatter.language}/>
        </Layout>
    );
};

export default ProductPage;
